<template>
  <!--论述题-->
  <div class="questionModule">
    <!--  {{stu}}-->
    <!--  {{formData}}-->
    <el-form ref="questionData" :model="questionData" label-width="100px" size="small" :rules="questionDataRules">
      <el-form-item label="题目内容" prop="questionName" class="form-item">
        <Editor
            :id="1"
            :html="questionData.questionName"
            @changeHtml="editor1"
        ></Editor>
      </el-form-item>
      <el-form-item label=" " prop="userShow" class="form-item" v-if="stu != 2&&stu != 3">
        <el-checkbox v-model="questionData.userShow">学员练习可见</el-checkbox>
      </el-form-item>
      <el-form-item label="参考答案" prop="referenceAnswers" class="form-item">
        <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入参考答案"
            v-model="questionData.referenceAnswers"
            maxlength="1500"
            style="max-width: 1200px"
            show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="题目解析" prop="answerAnalysis" class="form-item">
        <Editor
            :id="2"
            :html="questionData.answerAnalysis"
            @changeHtml="editor2"
        ></Editor>
      </el-form-item>
      <el-form-item class="btnBox">
        <el-button class="bgc-bv" @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('questionData')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>


import Editor from "@/components/editor.vue";
import List from "@/mixins/List";

export default {
  name: "EssayQuestion",
  components: {
    Editor
  },
  props: {
    stu: String,
    bankId: String,
    formData: Object,

    'questionInfo': {
      type: Object,
      default(){// 函数式默认值
        return null
      }
    }
  },
  mixins: [List],
  data() {
    return {
      questionData:{
        questionName: '',// 题目内容
        userShow: true,
        referenceAnswers: '',// 参考答案
        answerAnalysis: '',// 题目解析
      },
      questionDataRules: {
        questionName: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
        referenceAnswers: [
          { required: true, message: "请输入参考答案", trigger: "blur" },
        ],
        answerAnalysis: [
          { required: true, message: "请输入题目解析", trigger: "blur" },
        ],
      }

    };
  },
  computed: {},
  created() {

    // console.log(this.questionInfo)
    let _this = this
    // stu = 1 获取题目数据
    if (this.formData.questionId&&this.stu == '1') {
      this.getQuestionInfo(this.$route.query.questionId)
    }

    // stu = 2 获取题目数据
    // console.log('123123',this.stu,this.questionInfo,this.questionInfo != null)
    if (this.stu == '2' && this.questionInfo != null) {

      this.isEdit = true
      this.currentIndex = this.questionInfo.index

      this.questionData = {
        questionName: this.questionInfo.questionName,
        userShow: this.questionInfo.userShow,
        answerAnalysis: this.questionInfo.answerAnalysis,
        referenceAnswers:  this.questionInfo.answerList[0]
      }
    }

    // stu = 3 获取题目数据
    if (this.formData.paperQuestionId && this.stu == '3') {
      this.getPaperQuestionInfo(this.$route.query.paperQuestionId)
    }
  },
  methods: {
// 获取题目详情
    getQuestionInfo(questionId){
      let _this = this
      this.$post("/biz/exam/bank/question/getInfo", { questionId },3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              //给父组件赋值
              this.$parent.formData.questionType = ret.data.questionType
              this.$parent.formData.knowledgeId = ret.data.knowledgeId
              this.$parent.formData.difficultyLevel = ret.data.difficultyLevel

              this.questionData = {
                questionName: ret.data.questionName,
                userShow: ret.data.userShow,
                answerAnalysis: ret.data.answerAnalysis,
                referenceAnswers: ret.data.standardAnswer
              }
              // 选项赋值




            }
          })
          .catch((err) => {
            return;
          });
    },
    // 获取题目详情
    getPaperQuestionInfo(paperQuestionId){
      let _this = this
      this.$post("/biz/exam/bank/paper/question/getInfo", { paperQuestionId },3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              //给父组件赋值
              this.$parent.formData.questionType = ret.data.questionType
              // this.$parent.formData.knowledgeId = ret.data.knowledgeId
              // this.$parent.formData.difficultyLevel = ret.data.difficultyLevel

              this.questionData = {
                questionName: ret.data.questionName,
                userShow: ret.data.userShow,
                answerAnalysis: ret.data.answerAnalysis,
                referenceAnswers: ret.data.standardAnswer
              }
              // 选项赋值




            }
          })
          .catch((err) => {
            return;
          });
    },

    // 题目内容
    editor1(e) {
      this.questionData.questionName = e;
    },
    // 题目解析
    editor2(e) {
      this.questionData.answerAnalysis = e;
    },
    // 点击取消
    doClose() {
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      if(this.formData.stu == '1'||this.formData.stu == '3'){
        // 返回题目列表
        this.$parent.$router.back()
      }else if(this.formData.stu == '2'){
        // 关闭弹层
        this.$parent.handleClose()
      }
    },
    // 提交表单数据
    getSure(formData) {
      let _this = this
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      if(this.formData.stu == '1') {
        let hasFalse = false;
        this.$parent.$refs.formData.validate((valid) => {
          if(!this.formData.knowledgeId){
            this.$message.error('请选择知识点！')
            hasFalse = true
          }
          if (valid) {
            this.$refs[formData].validate((valid) => {
              // console.log(valid);
              // console.log(this.questionData);
              if (valid) {
                let hasFalse = false;
                if(this.questionData.questionName.length>2000){
                  this.$message.error('题目内容超过最大文本限制！')
                  hasFalse = true
                }
                if(this.questionData.answerAnalysis.length>1000){
                  this.$message.error('题目解析超过最大文本限制！')
                  hasFalse = true
                }

                if(!hasFalse){
                  // console.log('stu',this.stu)
                  // console.log('formData',this.formData)
                  // console.log('questionData',this.questionData)


                  // 提交参数集合
                  let params = {
                    bankId: this.formData.bankId, // 题库ID
                    questionType: this.formData.questionType, // 题目类型
                    difficultyLevel: this.formData.difficultyLevel, // 难度
                    knowledgeId: this.formData.knowledgeId, // 知识点

                    questionName: this.questionData.questionName, // 题目内容
                    userShow: this.questionData.userShow, // 学院练习可见
                    answerAnalysis: this.questionData.answerAnalysis, // 题目解析

                    answerList: [this.questionData.referenceAnswers], // 选项集合
                    // answerList: answerList, //正确答案集合
                  }

                  let url = "/biz/exam/bank/question/insert";// 新增接口地址
                  if(this.formData.questionId){
                    params.questionId = this.formData.questionId // 题目ID
                    url = "/biz/exam/bank/question/modify";// 修改接口地址
                  }

                  // console.log('提交写这里',params)

                  this.$post(url, params,3000,true,6)
                      .then((ret) => {

                        if (ret.status == 0) {
                          this.$message({
                            message: ret.message,
                            type: "success",
                          });
                          this.$parent.$router.push({
                            path: '/inkQuestionBank/QuestionBankManage/QuestionList',
                            query: {
                              refresh: true,
                              bankId: this.formData.bankId,
                              bankName: this.formData.bankName,
                              // questionNameStr: this.formData.questionNameStr,
                              // questionTypeStr: this.formData.questionTypeStr,
                            }
                          })
                        }
                      })
                      .catch((err) => {
                        return;
                      });
                }
              }
            });
          }
        })
      }else if(this.formData.stu == '2'){
        this.$refs[formData].validate((valid2) => {
          // console.log(this.questionData);
          if (valid2) {
            let hasFalse = false;


            if(this.questionData.questionName.length>2000){
              this.$message.error('题目内容超过最大文本限制！')
              hasFalse = true
            }
            if(this.questionData.answerAnalysis.length>1000){
              this.$message.error('题目解析超过最大文本限制！')
              hasFalse = true
            }
            if(!hasFalse){


              // 提交参数集合
              let params = {
                // bankId: this.formData.bankId, // 题库ID
                questionType: this.formData.questionType, // 题目类型
                // difficultyLevel: this.formData.difficultyLevel, // 难度
                // knowledgeId: this.formData.knowledgeId, // 知识点

                questionName: this.questionData.questionName, // 题目内容
                // userShow: this.questionData.userShow, // 学院练习可见
                answerAnalysis: this.questionData.answerAnalysis, // 题目解析

                // questionOptionList: this.tableData, // 选项集合
                answerList: [this.questionData.referenceAnswers], // 选项集合
              }
              if(this.isEdit){
                params.isEdit = this.isEdit
                params.currentIndex = this.currentIndex
              }
              this.$emit('successBack',params);
              this.$parent.handleClose()

            }
          }
        });
      }else if(this.formData.stu == '3') {

        this.$refs[formData].validate((valid) => {
              // console.log(valid);
              // console.log(this.questionData);
              if (valid) {
                let hasFalse = false;
                if(this.questionData.questionName.length>2000){
                  this.$message.error('题目内容超过最大文本限制！')
                  hasFalse = true
                }
                if(this.questionData.answerAnalysis.length>1000){
                  this.$message.error('题目解析超过最大文本限制！')
                  hasFalse = true
                }
                if(!hasFalse){
                  // console.log('stu',this.stu)
                  // console.log('formData',this.formData)
                  // console.log('questionData',this.questionData)


                  // 提交参数集合
                  let params = {
                    paperId: this.formData.paperId,
                    partitionId: this.formData.partitionId,

                    questionType: this.formData.questionType, // 题目类型
                    // difficultyLevel: this.formData.difficultyLevel, // 难度
                    // knowledgeId: this.formData.knowledgeId, // 知识点

                    questionName: this.questionData.questionName, // 题目内容
                    // userShow: this.questionData.userShow, // 学院练习可见
                    answerAnalysis: this.questionData.answerAnalysis, // 题目解析

                    answerList: [this.questionData.referenceAnswers], // 选项集合
                    // answerList: answerList, //正确答案集合
                  }

                  let url = "/biz/exam/bank/paper/question/insert";// 新增接口地址
                  if(this.formData.paperQuestionId){
                    params.paperQuestionId = this.formData.paperQuestionId // 题目ID
                    url = "/biz/exam/bank/paper/question/modify";// 修改接口地址
                  }

                  // console.log('提交写这里',params)

                  this.$post(url, params,3000,true,6)
                      .then((ret) => {

                        if (ret.status == 0) {
                          this.$message({
                            message: ret.message,
                            type: "success",
                          });
                          this.$parent.$router.back()
                        }
                      })
                      .catch((err) => {
                        return;
                      });
                }
              }
            });

      }

    },
  }
}
</script>

<style lang="less" scoped>
.btnBox{
  display: flex;justify-content: center;margin-top: 20px;
}
/deep/.el-form-item--small .el-form-item__error{
  padding-top: 6px;
}

/deep/ .seltAllbtnDis .cell .el-checkbox__inner {
  display: none;
  position: relative;
}
/deep/ .seltAllbtnDis .cell::before{
  content: '勾选正确答案';
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
/deep/.el-table th.el-table__cell{
  background: #ecf5ff!important;
  color: #333!important;
  border-color: #ecf5ff!important;
}
.img-el-upload{
  margin: 0 auto;
  width: 88px;
  height: 88px;

}
/deep/.el-upload{
  width: 100%;
  height: 100%;
}
/deep/.el-form-item{
  margin-bottom: 22px;
}
/deep/ .el-textarea .el-input__count {
  background: transparent !important;
}
//.editor{
//  width: 700px!important;
//}
</style>