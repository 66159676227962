<template>
  <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose">
    <div>
      <el-row>
        <el-form ref="subsetQuestionData" :inline="true" :model="subsetQuestionData" label-width="100px" size="small">
          <el-form-item label="子题目类型" prop="questionType" class="form-item" required>
            <el-select
                v-model="subsetQuestionData.questionType"
                placeholder="请选择子题目类型"
                :disabled="questionInfo!=null||!canchange"
            >
              <el-option
                  v-for="item in QuestionTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"

              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
      <div v-if="dialogVisible">
        <SingleChoiceQuestions @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 10"/>
        <MultipleChoiceQuestions @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 20"/>
        <Completion @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 40"/>
        <Judge @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 30"/>
        <ShortAnswer @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 50"/>
        <CalculationQuestions @successBack="successBack" :stu="subsetQuestionData.stu" :questionInfo="questionInfo" :formData="subsetQuestionData" v-if="subsetQuestionData.questionType == 60"/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// 单选题
import SingleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/SingleChoiceQuestions";
// 多选题
import MultipleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/MultipleChoiceQuestions";
// 填空题
import Completion from "@/views/inkQuestionBank/QuestionBankManage/questionType/Completion";
// 判断题
import Judge from "@/views/inkQuestionBank/QuestionBankManage/questionType/Judge";
// 简答题
import ShortAnswer from "@/views/inkQuestionBank/QuestionBankManage/questionType/ShortAnswer";
// 计算题
import CalculationQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/CalculationQuestions";
export default {
  name: "subsetAddOrEdit",
  components: {
    SingleChoiceQuestions,
    MultipleChoiceQuestions,
    Completion,
    Judge,
    ShortAnswer,
    CalculationQuestions,
  },
  props: {
  },
  data() {
    return {
      canchange: true,
      questionInfo: null,// 编辑子题目回显数据

      dialogTitle: '新增子题目',
      dialogVisible: false,
      subsetQuestionData: {
        questionType: '10',
        // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
        stu: '2',
      },
      // 题目类型下拉
      QuestionTypeList: [],
    }
  },
  methods: {
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");
      for (const key in QuestionType){
        if(key < 64){
          this.QuestionTypeList.push({
            value: key,
            label: QuestionType[key],
          });
        }
      }
    },
    // 打开弹窗
    showPopup(questionInfo,questionType) {
      this.dialogVisible = true
      if(questionInfo == null){
        this.questionInfo = null
      }else{
        this.dialogTitle = "修改子题目"
        this.questionInfo = questionInfo
        this.subsetQuestionData.questionType = questionInfo.questionType
      }
      // 如果是组合题(单一题型)已经有子题目了，子题目类型统一切不能修改
      if(questionType){
        this.canchange = false
        this.subsetQuestionData.questionType = questionType
      }


    },
    // 关闭弹窗
    handleClose(){
      this.canchange = true
      this.dialogVisible = false
    },
    // 给父级传参
    successBack(params){
      // console.log(1122334455)
      this.$emit('successBackMain',params);
    },

  },
  created() {
    // console.log('isAL',this.isAL)
    this.getQuestionTypeList();
  },
}
</script>

<style lang="less" scoped>

</style>