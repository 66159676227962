var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionModule"},[_c('el-form',{ref:"questionData",attrs:{"model":_vm.questionData,"label-width":"100px","size":"small","rules":_vm.questionDataRules}},[_c('el-form-item',{staticClass:"form-item",attrs:{"label":"题目内容","prop":"questionName"}},[_c('Editor',{attrs:{"id":1,"html":_vm.questionData.questionName},on:{"changeHtml":_vm.editor1}})],1),(_vm.stu != 2&&_vm.stu != 3)?_c('el-form-item',{staticClass:"form-item",attrs:{"label":" ","prop":"userShow"}},[_c('el-checkbox',{model:{value:(_vm.questionData.userShow),callback:function ($$v) {_vm.$set(_vm.questionData, "userShow", $$v)},expression:"questionData.userShow"}},[_vm._v("学员练习可见")])],1):_vm._e(),_c('el-form-item',{staticClass:"form-item",attrs:{"label":"选项内容","prop":"tableData"}},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%","max-width":"1200px"},attrs:{"data":_vm.tableData,"header-cell-style":_vm.tableHeader,"size":"small","tooltip-effect":"dark","stripe":"","header-cell-class-name":_vm.leftHeaderStyle},on:{"selection-change":_vm.selectItem}},[_c('el-table-column',{attrs:{"type":"selection","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"property":"content","label":"选项内容","min-width":"260","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"font-size":"14px","display":"inline-block","width":"20px"}},[_vm._v(_vm._s(_vm.optionFM(row.index))+".")]),_c('el-input',{staticStyle:{"display":"inline-block","width":"calc(100% - 20px)"},attrs:{"placeholder":"请输入选项内容"},model:{value:(row.content),callback:function ($$v) {_vm.$set(row, "content", $$v)},expression:"row.content"}})]}}])}),_c('el-table-column',{attrs:{"property":"address","label":"上传图片","min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-upload',{staticClass:"img-el-upload",attrs:{"on-change":function (res, file) {
                        return _vm.handleAvatarSuccess2(res, file, row);
                      },"before-upload":_vm.$beforeAvatarUpload,"http-request":_vm.$requestMine,"show-file-list":false,"action":"","accept":"image/png, image/gif, image/jpg, image/jpeg"}},[_c('el-image',{staticClass:"imgCenter",attrs:{"src":row.img ||
                        require('@/assets/develop.png'),"fit":"contain"}})],1)]}}])}),_c('el-table-column',{attrs:{"property":"address","label":"操作","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini","disabled":_vm.tableData.length<4},on:{"click":function($event){return _vm.delRow(row)}}},[_vm._v("删除选项")])]}}])})],1),_c('el-button',{staticStyle:{"margin-top":"10px"},attrs:{"type":"success","icon":"el-icon-plus","size":"mini","disabled":_vm.tableData.length>9},on:{"click":_vm.addRow}},[_vm._v("添加选项")])],1),_c('el-form-item',{staticClass:"form-item",attrs:{"label":"题目解析","prop":"answerAnalysis"}},[_c('Editor',{attrs:{"id":2,"html":_vm.questionData.answerAnalysis},on:{"changeHtml":_vm.editor2}})],1),_c('el-form-item',{staticClass:"btnBox"},[_c('el-button',{staticClass:"bgc-bv",on:{"click":_vm.doClose}},[_vm._v("取消")]),_c('el-button',{staticClass:"bgc-bv",on:{"click":function($event){return _vm.getSure('questionData')}}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }