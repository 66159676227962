<template>
  <div :ref="editor" class="editor" style="width: 100%; max-width: 1200px" />
</template>

<script>
import E from "wangeditor";
export default {
  props: {
    html: {
      type: [String],
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      text: "",
      eWidth: "",
      editor: "",
      editorThml:{}
    };
  },
  mounted() {
    this.editor = "editor" + this.id;
    setTimeout(() => {
      this.editorInit();
    }, 300);
  },
  methods: {

    // 富文本
    editorInit() {
      this.editorThml = new E(this.$refs[this.editor]);
      this.editorThml.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      this.editorThml.config.zIndex = 100;
      this.editorThml.config.uploadImgShowBase64 = true;
      this.editorThml.config.uploadImgMaxSize = 1 * 1024 * 1024;
      this.editorThml.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      this.editorThml.config.pasteFilterStyle = "text";
      this.editorThml.config.pasteIgnoreImg = true;

      this.editorThml.config.onchange = (html) => {
        this.$emit("changeHtml", html);
      };
      this.editorThml.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };

      // this.editorThml.config.pasteFilterStyle = "text";
      // this.editorThml.config.pasteIgnoreImg = true;
      // this.editorThml.config.onchange = (html) => {
      //   this.$emit("changeHtml", html);
      // };
      // this.editorThml.config.pasteTextHandle = function (content) {
      //   return content + "<p></p>";
      //   // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      // };

      //  // 监听实时富文本输入内容
      //     editor.customConfig.onchange=(html)={
      //       let info=html;
      //       this.$emit('change',info);
      //     }
      this.editorThml.create();
      const eWidth = this.$refs[this.editor].clientWidth;
      this.eWidth = eWidth;
      this.editorThml.txt.html(this.html);
    },
    insertAtCursor(val){
      if(this.editorThml){
        this.editorThml.cmd.do('insertHTML','<span>'+"____，"+'</span>')
      }

    },
  },
};
</script>

<style>
</style>