<template>
  <!--组合题(复合题型)-->
  <div class="questionModule">
    <!--  {{stu}}-->
    <!--  {{formData}}-->
    <el-form ref="mainQuestionData" :model="mainQuestionData" label-width="100px" size="small" :rules="mainQuestionDataRules">
      <el-form-item label="主题目内容" prop="questionName" class="form-item">
        <Editor
            :id="1"
            :html="mainQuestionData.questionName"
            ref="editor1"
            @changeHtml="editor8"
        ></Editor>
      </el-form-item>
      <el-form-item label=" " prop="userShow" class="form-item" v-if="stu != 2&&stu != 3">
        <el-checkbox v-model="mainQuestionData.userShow">学员练习可见</el-checkbox>
      </el-form-item>
      <el-form-item label=" " prop="userShow" class="form-item form-item-full">
        <el-button type="success" icon="el-icon-plus" size="mini" @click="updateSubset(null,null)" style="margin-bottom: 22px;" :disabled="tableData.length>5" v-if="stu!='3'">新增子题目</el-button>
        <div class="questionBox">
          <div v-for="(item,index) in examBankQuestionChildList" :key="index">
            <!--单选题-->
            <div class="questionItem" v-if="item.questionType == '10'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">单选题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionOption">
                   <span v-for="(itemInner,indexInner) in item.questionOptionList" :key="indexInner">
                      {{ optionFM(itemInner.index)}}.{{ itemInner.content }}
                      <p v-if="itemInner.img">
                        <el-image
                            style="height: 100px;min-height: 100px;"
                            :src="itemInner.img"
                            :fit="cover"
                            :preview-src-list="[itemInner.img]">
                        </el-image>
                      </p>
                    </span>
                  </div>
                  <div class="questionAnswer">
                      <span>
                        正确答案：{{ answerInit(item.answerList) }}
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                        解析：<span v-html="item.answerAnalysis"></span>
                      </span>
                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
            <!--多选题-->
            <div class="questionItem" v-if="item.questionType == '20'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">多选题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionOption">
                    <span v-for="(itemInner,indexInner) in item.questionOptionList" :key="indexInner">
                      {{ optionFM(itemInner.index)}}.{{ itemInner.content }}
                      <p v-if="itemInner.img">
                        <el-image
                            style="height: 100px;min-height: 100px;"
                            :src="itemInner.img"
                            :fit="cover"
                            :preview-src-list="[itemInner.img]">
                        </el-image>
                      </p>
                    </span>
                  </div>
                  <div class="questionAnswer">
                      <span>
                        正确答案：{{ answerInit(item.answerList) }}
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                        解析：<span v-html="item.answerAnalysis"></span>
                      </span>

                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
            <!--填空题-->
            <div class="questionItem" v-if="item.questionType == '40'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">填空题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionAnswer">
                      <span>
                        参考答案：
                        <p class="questionAnswerS" v-for="(itemInner,indexInner) in item.answerList" :key="indexInner">{{itemInner}}</p>
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                         解析：<span v-html="item.answerAnalysis"></span>
                      </span>

                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
            <!--判断题-->
            <div class="questionItem" v-if="item.questionType == '30'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">判断题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionOption">
                    <span v-for="(itemInner,indexInner) in item.questionOptionList" :key="indexInner">
                      {{ optionFM2(itemInner.index)}}.{{ itemInner.content }}
                      <p v-if="itemInner.img">
                        <el-image
                            style="height: 100px;min-height: 100px;"
                            :src="itemInner.img"
                            :fit="cover"
                            :preview-src-list="[itemInner.img]">
                        </el-image>
                      </p>
                    </span>
                  </div>
                  <div class="questionAnswer">
                      <span>
                        正确答案：{{ answerInit(item.answerList) }}
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                        解析：<span v-html="item.answerAnalysis"></span>
                      </span>

                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
            <!--简答题-->
            <div class="questionItem" v-if="item.questionType == '50'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">简答题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionAnswer">
                      <span>
                        参考答案：{{item.answerList[0]}}
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                         解析：<span v-html="item.answerAnalysis"></span>
                      </span>

                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
            <!--计算题-->
            <div class="questionItem" v-if="item.questionType == '60'">
              <div class="questionInner">
                <div class="questionInnerLeft">
                  <div class="questionTitle">
                    <span class="questionType">计算题</span><span>{{ index+1 }}.</span><span v-html="item.questionName" class="hh"></span>
                  </div>
                  <div class="questionAnswer">
                       <span>
                        参考答案：{{item.answerList[0]}}
                      </span>
                  </div>
                  <div class="questionAnalysis">
                      <span>
                         解析：<span v-html="item.answerAnalysis"></span>
                      </span>

                  </div>
                </div>
                <div class="questionInnerRight">
                  <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateSubset(item,index)">编辑子题目</el-button>
                  <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteSubset(index)">删除子题目</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item class="btnBox">
        <el-button class="bgc-bv" @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('mainQuestionData')">保存</el-button>
      </el-form-item>
    </el-form>
    <SubsetAddOrEdit  ref="SubsetAddOrEdit" @successBackMain="successBackMain"/>
  </div>
</template>

<script>
import Editor from "@/components/editor";
import List from "@/mixins/List";

// 新增编辑子题目
import SubsetAddOrEdit from "@/views/inkQuestionBank/QuestionBankManage/popup/SubsetAddOrEdit";

export default {
  name: "CaseAnalysisQuestions",
  components: {
    Editor,
    SubsetAddOrEdit,
  },
  props: {
    stu: String,
    bankId: String,
    formData: Object,

  },
  mixins: [List],
  data() {
    return {
      mainQuestionData:{
        questionName: '',// 题目内容
        userShow: true,
      },

      mainQuestionDataRules: {
        questionName: [
          { required: true, message: "请输入主题目内容", trigger: "blur" },
        ],
      },

      // 子题目列表
      examBankQuestionChildList: [],



      questionIdStr: '',// 仅试题提交回显时使用

    };
  },
  computed: {},
  created() {
    let _this = this
    // stu = 1 获取题目数据
    if(this.formData.questionId&&this.stu == '1'){
      this.getQuestionInfo(this.$route.query.questionId)
    }
    // stu = 3 获取题目数据
    if(this.formData.paperQuestionId&&this.stu == '3'){
      this.getPaperQuestionInfo(this.$route.query.paperQuestionId)
    }
  },
  methods: {
    // 获取题目详情
    getQuestionInfo(questionId){
      let _this = this
      this.$post("/biz/exam/bank/question/getInfo", { questionId },3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              //给父组件赋值
              this.$parent.formData.questionType = ret.data.questionType
              this.$parent.formData.knowledgeId = ret.data.knowledgeId
              this.$parent.formData.difficultyLevel = ret.data.difficultyLevel


              // 子题目回显
              let examBankQuestionChildList = []
              ret.data.childList.forEach((e,i)=>{
                let item = {}
                // 题目类型-单选||多选
                if( e.questionType == '10' ||  e.questionType == '20' ){
                  // 子选项集合
                  let questionOptionList = []

                  let optionJsonList = JSON.parse(e.optionJson) || []

                  optionJsonList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      index: _this.optionFN(eInner.key)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.standardAnswer.split(','),// 子正确答案集合
                  }

                }

                // 题目类型-判断
                if( e.questionType == '30' ){
                  // 子选项集合
                  let questionOptionList = []

                  let optionJsonList = JSON.parse(e.optionJson) || []

                  optionJsonList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      index: _this.optionFN2(eInner.key)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.standardAnswer.split(','),// 子正确答案集合
                  }

                }

                // 题目类型-填空
                if( e.questionType == '40' ){

                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析

                    answerList: e.standardAnswer.split('####'),// 子正确答案集合
                  }

                }

                // 题目类型-填空
                if( e.questionType == '50' || e.questionType == '60' ){

                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析

                    answerList: [e.standardAnswer],// 子正确答案集合

                  }

                }


                examBankQuestionChildList.push(item)
              })


              this.mainQuestionData = {
                questionName: ret.data.questionName,
                userShow: ret.data.userShow,
              }
              this.examBankQuestionChildList = examBankQuestionChildList
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 获取试卷题目详情
    getPaperQuestionInfo(paperQuestionId){
      let _this = this
      this.$post("/biz/exam/bank/paper/question/getInfo", { paperQuestionId },3000,true,6)
          .then((ret) => {
            if (ret.status == 0) {
              //给父组件赋值
              this.$parent.formData.questionType = ret.data.questionType
              // console.log('1233333',ret.data.questionId)
              this.questionIdStr = ret.data.questionId
              // this.$parent.formData.knowledgeId = ret.data.knowledgeId
              // this.$parent.formData.difficultyLevel = ret.data.difficultyLevel


              // 子题目回显
              let examBankQuestionChildList = []
              ret.data.childList.forEach((e,i)=>{
                let item = {}
                // 题目类型-单选||多选
                if( e.questionType == '10' ||  e.questionType == '20' ){
                  // 子选项集合
                  let questionOptionList = []

                  let optionJsonList = JSON.parse(e.optionJson) || []

                  optionJsonList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      index: _this.optionFN(eInner.key)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.standardAnswer.split(','),// 子正确答案集合
                    score: e.score,// 分数
                  }

                }

                // 题目类型-判断
                if( e.questionType == '30' ){
                  // 子选项集合
                  let questionOptionList = []

                  let optionJsonList = JSON.parse(e.optionJson) || []

                  optionJsonList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      index: _this.optionFN2(eInner.key)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.standardAnswer.split(','),// 子正确答案集合
                    score: e.score,// 分数
                  }

                }

                // 题目类型-填空
                if( e.questionType == '40' ){

                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析

                    answerList: e.standardAnswer.split('####'),// 子正确答案集合
                    score: e.score,// 分数
                  }

                }

                // 题目类型-填空
                if( e.questionType == '50' || e.questionType == '60' ){

                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析

                    answerList: [e.standardAnswer],// 子正确答案集合
                    score: e.score,// 分数
                  }

                }


                examBankQuestionChildList.push(item)
              })


              this.mainQuestionData = {
                questionName: ret.data.questionName,
                userShow: ret.data.userShow,
              }
              this.examBankQuestionChildList = examBankQuestionChildList
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 列表答案初始化
    answerInit(answer){
      return answer.toString()
    },
    // 添加编辑子题目
    updateSubset(info,index){
      if(info == null){
        this.$refs.SubsetAddOrEdit.showPopup(null)
      }else{
        let questionInfo = {
          index,
          ...info
        }
        this.$refs.SubsetAddOrEdit.showPopup(questionInfo)
      }

    },
    // 删除子题目
    deleteSubset(i){
      this.examBankQuestionChildList.splice(i,1)
    },
    // 添加编辑子题目成功回调
    successBackMain(params){
      // console.log('5544332211',this.examBankQuestionChildList)
      if(params.isEdit){
        //合并对象
        let currentParams= Object.assign(this.examBankQuestionChildList[params.currentIndex],params);
        this.examBankQuestionChildList[params.currentIndex] = currentParams;

      }else{
        this.examBankQuestionChildList.push(params)
      }

    },
    // 题目内容
    editor8(e) {
      this.mainQuestionData.questionName = e;
    },
    // 点击取消
    doClose() {
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      if(this.formData.stu == '1'||this.formData.stu == '3'){
        // 返回题目列表
        this.$parent.$router.back()
      }else if(this.formData.stu == '2'){
        // 关闭弹层
        this.$parent.handleClose()
      }
    },
    // 提交表单数据
    getSure(formData) {
      let _this = this
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      if(this.formData.stu == '1') {
        let hasFalse = false; //有没有填写不对的表单项

        this.$parent.$refs.formData.validate((valid) => {

          if(!this.formData.knowledgeId){
            this.$message.error('请选择知识点！')
            hasFalse = true
          }

          if (valid) {
            this.$refs[formData].validate((valid) => {
              // console.log(valid);
              // console.log(this.questionData);
              if (valid) {

                if(this.examBankQuestionChildList.length == 0){
                  this.$message.error('请至少添加一个子题目！')
                  hasFalse = true
                }
                if(this.mainQuestionData.questionName.length>2000){
                  this.$message.error('题目内容超过最大文本限制！')
                  hasFalse = true
                }
                if(!hasFalse){
                  // console.log('stu',this.stu)
                  // console.log('formData',this.formData)
                  // console.log('questionData',this.questionData)

                  let examBankQuestionChildList = []
                  this.examBankQuestionChildList.forEach((e,i)=>{
                    // console.log('current',e)
                    let item = {}


                    // 子题目类型-单选题||多选
                    if( e.questionType == '10' || e.questionType == '20' ){
                      // 子选项集合
                      let questionOptionList = []
                      e.questionOptionList.forEach((eInner,iInner)=>{
                        let itemInner = {
                          content: eInner.content,
                          img: eInner.img,
                          key: _this.optionFM(eInner.index)
                        }
                        questionOptionList.push(itemInner)
                      })
                      item = {
                        questionType: e.questionType,// 子题目类型
                        questionName: e.questionName,// 子题目内容
                        answerAnalysis: e.answerAnalysis,// 子题目解析
                        questionOptionList: questionOptionList,// 子选项集合
                        answerList: e.answerList,// 子正确答案集合
                      }
                    }

                    // 子题目类型-判断
                    if( e.questionType == '30' ){
                      // 子选项集合
                      let questionOptionList = []
                      e.questionOptionList.forEach((eInner,iInner)=>{
                        let itemInner = {
                          content: eInner.content,
                          img: eInner.img,
                          key: _this.optionFM2(eInner.index)
                        }
                        questionOptionList.push(itemInner)
                      })
                      item = {
                        questionType: e.questionType,// 子题目类型
                        questionName: e.questionName,// 子题目内容
                        answerAnalysis: e.answerAnalysis,// 子题目解析
                        questionOptionList: questionOptionList,// 子选项集合
                        answerList: e.answerList,// 子正确答案集合
                      }
                    }

                    // 子题目类型-填空题
                    if( e.questionType == '40' ){
                      item = {
                        questionType: e.questionType,// 子题目类型
                        questionName: e.questionName,// 子题目内容
                        answerAnalysis: e.answerAnalysis,// 子题目解析
                        answerList: e.answerList,// 子正确答案集合
                      }
                    }

                    // 子题目类型-简答题||计算题
                    if( e.questionType == '50' || e.questionType == '60' ){
                      item = {
                        questionType: e.questionType,// 子题目类型
                        questionName: e.questionName,// 子题目内容
                        answerAnalysis: e.answerAnalysis,// 子题目解析

                        answerList: e.answerList,// 子正确答案集合
                      }
                    }

                    examBankQuestionChildList.push(item)
                  })


                  // 提交参数集合
                  let params = {
                    bankId: this.formData.bankId, // 题库ID
                    questionType: this.formData.questionType, // 题目类型
                    difficultyLevel: this.formData.difficultyLevel, // 难度
                    knowledgeId: this.formData.knowledgeId, // 知识点

                    questionName: this.mainQuestionData.questionName, // 题目内容
                    userShow: this.mainQuestionData.userShow, // 学院练习可见

                    examBankQuestionChildList: examBankQuestionChildList //子题目列表
                  }

                  let url = "/biz/exam/bank/question/insert";// 新增接口地址
                  if(this.formData.questionId){
                    params.questionId = this.formData.questionId // 题目ID
                    url = "/biz/exam/bank/question/modify";// 修改接口地址
                  }

                  // console.log('提交写这里',params)

                  this.$post(url, params,3000,true,6)
                      .then((ret) => {

                        if (ret.status == 0) {
                          this.$message({
                            message: ret.message,
                            type: "success",
                          });
                          this.$parent.$router.push({
                            path: '/inkQuestionBank/QuestionBankManage/QuestionList',
                            query: {
                              refresh: true,
                              bankId: this.formData.bankId,
                              bankName: this.formData.bankName,
                              // questionNameStr: this.formData.questionNameStr,
                              // questionTypeStr: this.formData.questionTypeStr,
                            }
                          })
                        }
                      })
                      .catch((err) => {
                        return;
                      });
                }
              }
            });
          }
        })
      }else if(this.formData.stu == '3') {

        this.$refs[formData].validate((valid) => {
              // console.log(valid);
              // console.log(this.questionData);
              if (valid) {
                let hasFalse = false
                if(this.examBankQuestionChildList.length == 0){
                  this.$message.error('请至少添加一个子题目！')
                  hasFalse = true
                }
                if(this.mainQuestionData.questionName.length>2000){
                  this.$message.error('题目内容超过最大文本限制！')
                  hasFalse = true
                }
                if(!hasFalse){
                  // console.log('stu',this.stu)
                  // console.log('formData',this.formData)
                  // console.log('questionData',this.questionData)

              let examBankQuestionChildList = []
              this.examBankQuestionChildList.forEach((e,i)=>{
                // console.log('current',e)
                let item = {}


                // 子题目类型-单选题||多选
                if( e.questionType == '10' || e.questionType == '20' ){
                  // 子选项集合
                  let questionOptionList = []
                  e.questionOptionList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      key: _this.optionFM(eInner.index)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.answerList,// 子正确答案集合
                    score: e.score,// 分数
                  }
                }

                // 子题目类型-判断
                if( e.questionType == '30' ){
                  // 子选项集合
                  let questionOptionList = []
                  e.questionOptionList.forEach((eInner,iInner)=>{
                    let itemInner = {
                      content: eInner.content,
                      img: eInner.img,
                      key: _this.optionFM2(eInner.index)
                    }
                    questionOptionList.push(itemInner)
                  })
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    questionOptionList: questionOptionList,// 子选项集合
                    answerList: e.answerList,// 子正确答案集合
                    score: e.score,// 分数
                  }
                }

                // 子题目类型-填空题
                if( e.questionType == '40' ){
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析
                    answerList: e.answerList,// 子正确答案集合
                    score: e.score,// 分数
                  }
                }

                // 子题目类型-简答题||计算题
                if( e.questionType == '50' || e.questionType == '60' ){
                  item = {
                    questionType: e.questionType,// 子题目类型
                    questionName: e.questionName,// 子题目内容
                    answerAnalysis: e.answerAnalysis,// 子题目解析

                    answerList: e.answerList,// 子正确答案集合
                    score: e.score,// 分数
                  }
                }

                examBankQuestionChildList.push(item)
              })

                  // console.log('3333321',this.questionIdStr)
                  // 提交参数集合
                  let params = {
                    paperId: this.formData.paperId,
                    partitionId: this.formData.partitionId,

                    questionId: this.questionIdStr,

                    questionType: this.formData.questionType, // 题目类型
                    // difficultyLevel: this.formData.difficultyLevel, // 难度
                    // knowledgeId: this.formData.knowledgeId, // 知识点

                questionName: this.mainQuestionData.questionName, // 题目内容
                // userShow: this.mainQuestionData.userShow, // 学院练习可见

                examBankQuestionChildList: examBankQuestionChildList //子题目列表
              }

              let url = "/biz/exam/bank/paper/question/insert";// 新增接口地址
              if(this.formData.paperQuestionId){
                params.paperQuestionId = this.formData.paperQuestionId // 题目ID
                url = "/biz/exam/bank/paper/question/modify";// 修改接口地址
              }

              // console.log('提交写这里',params)

              this.$post(url, params,3000,true,6)
                  .then((ret) => {

                    if (ret.status == 0) {
                      this.$message({
                        message: ret.message,
                        type: "success",
                      });
                      // 编辑 - 试卷
                      this.$parent.$router.back()
                    }
                  })
                  .catch((err) => {
                    return;
                  });
            }
          }
        });

      }
    },
    // 选项顺序替换成字母
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    // 选项字母替换成顺序
    optionFN(msg) {
      if (msg == "A") {
        return 0;
      } else if (msg == "B") {
        return 1;
      } else if (msg == "C") {
        return 2;
      } else if (msg == "D") {
        return 3;
      } else if (msg == "E") {
        return 4;
      } else if (msg == "F") {
        return 5;
      }
    },
    // 选项顺序替换成字母
    optionFM2(msg) {
      if (msg == 0) {
        return "T";
      } else if (msg == 1) {
        return "F";
      }
    },
    // 选项字母替换成顺序
    optionFN2(msg) {
      if (msg == "T") {
        return 0;
      } else if (msg == "F") {
        return 1;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.btnBox{
  display: flex;justify-content: center;margin-top: 20px;
}
/deep/.el-form-item--small .el-form-item__error{
  padding-top: 6px;
}

/deep/ .seltAllbtnDis .cell .el-checkbox__inner {
  display: none;
  position: relative;
}
/deep/ .seltAllbtnDis .cell::before{
  content: '勾选正确答案';
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
/deep/.el-table th.el-table__cell{
  background: #ecf5ff!important;
  color: #333!important;
  border-color: #ecf5ff!important;
}
.img-el-upload{
  margin: 0 auto;
  width: 88px;
  height: 88px;

}
/deep/.el-upload{
  width: 100%;
  height: 100%;
}
/deep/.el-form-item{
  margin-bottom: 22px;
}
/*新增子题目*/
.subset{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
}
.form-item-full /deep/.el-form-item__label{
  width: 0!important;
}
.form-item-full /deep/.el-form-item__content{
  margin-left: 0!important;
}


//题目样式
.questionBox > div{
  margin-bottom: 15px;
  &:last-child{
    margin-bottom: 0;
  }
}
//.questionItem{
//  margin-bottom: 15px;
//  &:last-child{
//    margin-bottom: 0;
//  }
//}
.questionInner{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;
  &:hover{
    background: #ecf5ff;
  }
}
.questionInnerLeft{
  position: relative;
  width: 100%;
}
.questionTitle{
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.questionType{
  white-space:nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}
.questionOption{
  display: flex;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;
  span{
    width: 25%;
    margin-bottom: 10px;
  }
}
.questionAnswer{
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;
  &>span{
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }
  .questionAnswerS{
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133,206,97,0.3);
    &:last-child{
      border: 0;
    }
  }
}
.questionAnalysis{
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;
  &>span{
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
}
.questionInnerRight{
  min-width: 228px;
  margin-left: 15px;
}
.showChildrenQuestion{
  cursor: pointer;
  color: #2878ff;
}
.childrenQuestionItem{
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  //&:last-child{
  //  margin-bottom: 0;
  //}
}
//强制换行
.hh{
  word-break: break-all;
  word-wrap: break-word;
  //white-space: pre-wrap;
}
//.editor{
//  width: 700px!important;
//}
</style>